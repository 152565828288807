import { useState, useEffect } from 'react';

import cs from 'classnames';
import Link from 'next/link';

import LogoPC from '@/assets/images/logo-header-pc.svg';
import LogoSP from '@/assets/images/logo-header-sp.svg';
import { ContainerComponent, ButtonComponent } from '@/components';
import { handleRedirectNewTab } from '@/utils/redirect';

const MENU_LIST = [
  {
    id: 1,
    text: 'TAG LIVE LABELとは？',
    name: 'what-is-tag-live-label',
  },
  {
    id: 2,
    text: '活用シーン',
    name: 'scene',
  },
  {
    id: 3,
    text: '導入実績',
    name: 'case-studies',
  },
  {
    id: 4,
    text: '導入のしやすさ',
    name: 'easy-to-introduce',
  },
  {
    id: 5,
    text: 'よくあるご質問',
    name: 'faq',
  },
] as const;

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [offsetTop, setOffsetTop] = useState(false);

  const classNamesHeader = cs(
    'bg-white py-[0.53125rem] pc:py-[0.6875rem] sticky top-0 z-10',
    { 'shadow-header': offsetTop, 'z-20': isNavOpen }
  );
  const classNamesHeaderWrapper = cs(
    'fixed pc:relative opacity-0 pc:opacity-100 inset-0 bg-gray-400 pc:bg-white overflow-auto',
    {
      'z-30 opacity-100 visible ease-linear duration-300': isNavOpen,
      'z-10 invisible pc:visible ease-linear duration-300': !isNavOpen,
    }
  );
  const classNamesIcon1 = cs('line-icon mt-0.5', {
    'translate-y-2.5 -rotate-45': isNavOpen,
  });
  const classNamesIcon2 = cs('line-icon mt-2', { 'opacity-0': isNavOpen });
  const classNamesIcon3 = cs('line-icon mt-2', {
    '-translate-y-2.5 rotate-45': isNavOpen,
  });

  const handleBodyScrollable = (mustHidden = false) => {
    document.body.style.overflow = mustHidden ? 'hidden' : 'initial';
  };

  const handleCloseMenu = () => {
    handleBodyScrollable();
    setIsNavOpen(false);
  };

  const handleOpenMenu = () => {
    handleBodyScrollable(!isNavOpen);
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setOffsetTop(currentScrollY > 10);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      handleBodyScrollable();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={classNamesHeader}>
      <ContainerComponent size="large">
        <nav className="flex-between">
          <h1 className="z-50">
            <Link
              href="/"
              className="cursor-default pc:cursor-pointer"
              onClick={handleCloseMenu}
            >
              <LogoPC className="hidden pc:block" />
              <LogoSP className="pc:hidden" />
            </Link>
          </h1>
          <div className={classNamesHeaderWrapper}>
            <div className="pc:space-x-6 pc-large:space-x-8 flex items-center flex-col justify-end pc:flex-row px-5 pc:px-0 pt-[3.375rem] pc:pt-0 pb-5 pc:pb-0">
              {MENU_LIST.map((item) => (
                <Link
                  key={item.id}
                  href={`#${item.name}`}
                  scroll={false}
                  className="text-lg pc:text-sm font-bold mt-8 pc:mt-0 cursor-default pc:cursor-pointer hover:text-gray-600"
                  onClick={handleCloseMenu}
                >
                  {item.text}
                </Link>
              ))}
              <ButtonComponent
                icon
                isHeader
                btnType="primary"
                className="w-full relative flex-centered h-15 pc:h-12 text-base pc:text-sm pc:pl-[1.1875rem] pc:pr-[0.9375rem] mt-8 pc:mt-0 pc:!ml-6 max-w-[20.9375rem] pc:w-auto"
                onClick={() => {
                  handleCloseMenu();
                  handleRedirectNewTab();
                }}
              >
                <span className="pc:hidden">お問い合わせはこちら</span>
                <span className="hidden pc:block">お問い合わせ</span>
              </ButtonComponent>
            </div>
          </div>
          <button
            className="pc:hidden w-[1.625rem] h-6 z-[100]"
            onClick={handleOpenMenu}
          >
            <span className={classNamesIcon1}></span>
            <span className={classNamesIcon2}></span>
            <span className={classNamesIcon3}></span>
          </button>
        </nav>
      </ContainerComponent>
    </header>
  );
};

export default Header;
