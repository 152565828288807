import React from 'react';

import Image from 'next/image';

import IconBox from '@/assets/icons/icon-box.svg';
import IconCase from '@/assets/icons/icon-case.svg';
import IconMitigation from '@/assets/icons/icon-mitigation.svg';
import IconPrinter from '@/assets/icons/icon-printer.svg';
import IconScreen from '@/assets/icons/icon-screen.svg';
import IconTitleCostTl from '@/assets/icons/icon-title-cost-tl.svg';
import IconTitleCost from '@/assets/icons/icon-title-cost.svg';
import IconTitleRiskTl from '@/assets/icons/icon-title-risk-tl.svg';
import IconTitleRisk from '@/assets/icons/icon-title-risk.svg';
import IconVector from '@/assets/icons/icon-vector.svg';
import IconZeroYen from '@/assets/icons/icon-zero-yen.svg';
import ImageTopArrow from '@/assets/images/easy-to-introduce/top-arrow.png';
import {
  ContainerComponent,
  SectionTitleComponent,
  IntroductionInventoryComponent,
  ButtonComponent,
} from '@/components';
import { EasyToIntroduceDataDef } from '@/models/landingpage.types';
import { handleRedirectNewTab } from '@/utils/redirect';

const EASY_TO_INTRODUCE_DATA: EasyToIntroduceDataDef[] = [
  {
    mainTitle: (
      <>
        <IconTitleCost className="tl:hidden" />
        <IconTitleCostTl className="hidden tl:block" />
      </>
    ),
    topContent: (
      <span className="flex justify-center">
        <IconZeroYen />
      </span>
    ),
    contents: [
      {
        title: 'システム導入費無料',
        description: '専用ラベルシステムを導入いただく際に費用はかかりません。',
        textNote:
          '※システムを利用する際に必要な環境はご用意いただく必要があります。',
        icon: <IconScreen />,
      },
      {
        title: 'プリンターは無料で提供',
        description: '専用のプリンターは弊社から無料で貸与します。',
        textNote: '※ラベルの用紙や販売いただくドリンクは別途費用がかかります。',
        icon: <IconPrinter />,
      },
    ],
  },
  {
    mainTitle: (
      <>
        <IconTitleRisk className="tl:hidden" />
        <IconTitleRiskTl className="hidden tl:block" />
      </>
    ),
    isLast: true,
    topContent: (
      <span className="flex justify-center items-end relative">
        <IconMitigation />
        <IconVector className="absolute top-7 left-[calc(50%+3.25rem)]" />
      </span>
    ),
    contents: [
      {
        title: '小ロットから発注可能',
        description:
          '小ロットから缶の発注が可能です。必要なドリンクの数だけラベルを印刷することで在庫リスクを抑えることができます。',
        icon: <IconCase />,
      },
      {
        title: '売れ残りを最小限に',
        description:
          'ラベルを多品種で展開しても、専用缶は共通のものを利用できるため、売れ残りを抑えることができます。',
        icon: <IconBox />,
      },
    ],
  },
];

const EasyToIntroduceSection = () => (
  <section className="bg-gray-200">
    <Image
      src={ImageTopArrow}
      alt="arrow background"
      className="h-13 tl:h-[7.25rem] -translate-y-0.5"
    />
    <ContainerComponent size="large" className="py-10 tl:py-12">
      <SectionTitleComponent
        id="easy-to-introduce"
        title="EASY TO INTRODUCE"
        description="導入のしやすさ"
      />
      <ul className="mt-11 tl:mt-15 grid gap-11 tl:grid-cols-2 tl:gap-5 pc:gap-10">
        {EASY_TO_INTRODUCE_DATA.map(
          ({ mainTitle, contents, topContent, isLast }, idx: number) => (
            <li key={idx}>
              <IntroductionInventoryComponent
                isLast={isLast}
                contents={contents}
                mainTitle={mainTitle}
                topContent={topContent}
              />
            </li>
          )
        )}
      </ul>
      <div className="text-center mt-6 tl:mt-10">
        <ButtonComponent
          icon
          btnType="primary"
          className="w-full tl:max-w-[21.875rem] h-15 text-white relative"
          onClick={handleRedirectNewTab}
        >
          お問い合わせはこちら
        </ButtonComponent>
      </div>
    </ContainerComponent>
  </section>
);
export default EasyToIntroduceSection;
