const detectIsIOSOrSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isIOS = /iPad|iPhone/.test(userAgent);
  // detect safari browser https://stackoverflow.com/questions/7944460/detect-safari-browser.
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  return isIOS || isSafari;
};

export { detectIsIOSOrSafari };
