import 'react-loading-skeleton/dist/skeleton.css';
import IconPriceZero from '@/assets/icons/twitter-embedded/icon-price-zero.svg';
import {
  ButtonComponent,
  ClientComponent,
  ContainerComponent,
  TwitterEmbeddedComponent,
} from '@/components';
import { handleRedirectNewTab } from '@/utils/redirect';

const ZERO_PRICE_TITLES = ['初期コスト', '運用コスト', '在庫リスク'];

const TwitterEmbeddedSection = () => (
  <section>
    <ContainerComponent size="large" className="pb-10 tl:py-10 pc:py-12">
      <div className="p-5 tl:p-8 pc:p-10 rounded-2xl bg-gray-200 tl:flex tl:items-start">
        <div className="flex-1">
          <h3 className="text-xl tl:text-2xl font-bold !leading-normal">
            自販機を使って自社が保有するIPのオリジナルラベル缶を販売してみませんか？
          </h3>
          <p className="mt-2 text-sm leading-[1.625rem]">
            自販機×ランダムのガチャ体験でお客様に新たな価値を届けられます！
            <br />
            常設ショップやイベントで超簡単に導入可能
            <br />
            様々なIPコンテンツとのコラボレーション自販機は公式Xをチェック！
          </p>
          <h4 className="pc:text-lg font-bold mt-6 !leading-[1.4] relative pl-[0.8125rem] before:left-0 before:top-1/2 before:-translate-y-1/2 before:absolute before:w-[0.3125rem] before:h-full before:bg-secondary">
            導入企業様作業はラベルと自販機のデザイン制作のみ！
          </h4>
          <ul className="mt-4 space-x-5 flex">
            {ZERO_PRICE_TITLES.map((title) => (
              <li key={title} className="flex flex-col">
                <span className="text-sm font-bold leading-[1.3]">{title}</span>
                <IconPriceZero />
              </li>
            ))}
          </ul>
          <p className="leading-[1.6] mt-3 text-sm">
            ※導入条件：販売見込み<span className="font-bold">2000本</span>
            以上。詳細はお問い合わせください。
          </p>
          <ButtonComponent
            icon
            btnType="primary"
            className="w-full tl:max-w-[16.6875rem] h-12 text-white relative mt-6"
            onClick={handleRedirectNewTab}
          >
            お問い合わせはこちら
          </ButtonComponent>
        </div>
        <div className="tl:w-[20rem] pc:w-[23.75rem] mt-5 tl:mt-0 tl:ml-6 pc:ml-10 aspect-[3/4] tl:aspect-[380/420] relative">
          <ClientComponent>
            <TwitterEmbeddedComponent />
          </ClientComponent>
        </div>
      </div>
    </ContainerComponent>
  </section>
);

export default TwitterEmbeddedSection;
