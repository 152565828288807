import React from 'react';

import Image from 'next/image';

import IconTitlePc from '@/assets/icons/overview-service/icon-title-pc.svg';
import IconTitleTl from '@/assets/icons/overview-service/icon-title-tl.svg';
import IconTitle from '@/assets/icons/overview-service/icon-title.svg';
import ImageOverviewService from '@/assets/images/overview-service/overview-service.png';
import { ContainerComponent, Br } from '@/components';

const OverviewServiceSection = () => (
  <section>
    <ContainerComponent size="large" className="py-10 pc:pb-12 pc:pt-20">
      <div className="tl:flex pc:items-center">
        <IconTitle className="tl:hidden" />
        <div className="tl:w-[17.5rem] mt-8 tl:mt-0 pc:w-[28.125rem]">
          <Image
            src={ImageOverviewService}
            alt="ラベルの貼り付けイメージとラベル編集画面"
          />
        </div>
        <div className="mt-4 tl:mt-0 leading-[1.875rem] pc:ml-[30px] tl:ml-5 tl:flex-1">
          <IconTitleTl className="hidden tl:block pc:hidden" />
          <IconTitlePc className="hidden pc:block" />
          <p className="tl:mt-4">
            専用のラベルシステムを使うことで、
            <Br forOnly="desktop" />
            <Br forOnly="tablet" />
            特別なコンテンツが入ったオリジナルドリンクを
            <Br forOnly="desktop" />
            <Br forOnly="tablet" />
            簡単に作成、販売することができます。
          </p>
          <p className="mt-4 tl:mt-2">
            <span className="text-lg font-black mr-[0.1875rem]">
              その日、その場所だけの特別な1本
            </span>
            を<Br forOnly="mobile" />
            <Br forOnly="tablet" />
            販売することで、
            <Br forOnly="desktop" />
            お客様の思い出を
            <Br forOnly="tablet" />
            特別なものにします。
          </p>
        </div>
      </div>
    </ContainerComponent>
  </section>
);

export default OverviewServiceSection;
