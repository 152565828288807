import React from 'react';

import Image from 'next/image';
import Link from 'next/link';
import Script from 'next/script';
import Skeleton from 'react-loading-skeleton';

import { detectIsIOSOrSafari } from '@/utils/platform';

const TwitterEmbedded = () => {
  const isTablet = window.matchMedia('(min-width: 768px)').matches;
  const isDesktop = window.matchMedia('(min-width: 1024px)').matches;
  const mobileHeight = ((window.innerWidth - 80) * 4) / 3;
  const isIOSOrSafari = detectIsIOSOrSafari();

  return (
    <>
      {isIOSOrSafari ? (
        <Link target="_blank" href="https://x.com/TAGLIVELABEL">
          <Image
            fill
            alt="twitter banner"
            src={process.env.NEXT_PUBLIC_TWITTER_BANNER_URL ?? ''}
          />
        </Link>
      ) : (
        <>
          <a
            className="twitter-timeline"
            data-lang="en"
            data-width="100%"
            data-height={isDesktop ? 420 : isTablet ? 354 : mobileHeight}
            data-dnt="true"
            data-theme="light"
            href="https://twitter.com/TAGLIVELABEL?ref_src=twsrc%5Etfw"
          >
            <div className="overflow-hidden h-full">
              <Skeleton className="h-10 mb-1" />
              <Skeleton className="h-5 mt-2" count={20} />
            </div>
          </a>
          <Script
            async
            strategy="afterInteractive"
            src="https://platform.twitter.com/widgets.js"
            charSet="utf-8"
          />
        </>
      )}
    </>
  );
};

export default TwitterEmbedded;
